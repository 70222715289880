<template>
  <div>
    <b-card>
      <b-row>
        <b-col cols="6">
          <b-form-group>
            <v-select
              v-model="filterData.id_com_brand"
              label="title"
              :options="brands"
              placeholder="Marka Seçiniz"
              :reduce="brand => brand.id"
              :close-on-select="false"
              multiple
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group>
            <v-select
              v-model="filterData.id_com_meet"
              label="title"
              :options="meets"
              placeholder="Temas Durumu Seçiniz"
              :reduce="meet => meet.id"
              :close-on-select="false"
              multiple
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group>
            <b-form-datepicker
              v-model="filterData.sdate"
              v-bind="{labelNoDateSelected: 'Başlangıç Tarihi',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
              locale="tr"
              start-weekday="1"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group>
            <b-form-datepicker
              v-model="filterData.edate"
              v-bind="{labelNoDateSelected: 'Bitiş Tarihi',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
              locale="tr"
              start-weekday="1"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group>
            <v-select
              v-model="filterData.status"
              label="title"
              :options="filterData.statuses"
              placeholder="Durum Seçiniz"
              :reduce="status => status.id"
              :close-on-select="false"
              multiple
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group>
            <v-select
              v-model="filterData.id_com_user"
              label="name"
              :options="users"
              placeholder="Danışman Seçiniz"
              :reduce="user => user.id"
              :close-on-select="false"
              multiple
            >
              <template v-slot:option="option">
                <div v-if="option.brand">
                  {{ option.name }}
                  <div>
                    <small class="text-warning">{{ option.brand }} / {{ option.type }}</small>
                  </div>
                </div>
                <div v-else>
                  {{ option.name }}
                </div>
              </template>
              <template v-slot:selected-option="option">
                <div v-if="option.brand">
                  {{ option.name }}
                  <small class="text-warning">{{ option.brand }} / {{ option.type }}</small>
                </div>
                <div v-else>
                  {{ option.name }}
                </div>
              </template>
            </v-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-button
            variant="primary"
            :disabled="filterData.sdate == null || filterData.edate == null || filterData.id_com_meet.length === 0"
            @click="getReport"
          >
            <FeatherIcon icon="FilterIcon" />
            Görüntüle
          </b-button>
        </b-col>
        <b-col
          md="auto"
          class="text-right"
        >
          <b-button
            v-if="digitalResponse.length > 0"
            variant="success"
            class="ml-1"
            :disabled="filterData.sdate == null || filterData.edate == null || filterData.id_com_meet.length === 0"
            :href="downloadExcel"
            target="_blank"
          >
            <FeatherIcon icon="FileIcon" />
            Excel Aktar
          </b-button>
          <b-button
            v-if="digitalResponse.length > 0"
            variant="danger"
            class="ml-1"
            :disabled="filterData.sdate == null || filterData.edate == null || filterData.id_com_meet.length === 0"
            :href="showPDF"
            target="_blank"
          >
            <FeatherIcon icon="FileIcon" />
            Özet PDF İndir
          </b-button>
          <b-button
            v-if="digitalResponse.length > 0"
            variant="danger"
            class="ml-1"
            :disabled="filterData.sdate == null || filterData.edate == null || filterData.id_com_meet.length === 0"
            :href="showDetailPDF"
            target="_blank"
          >
            <FeatherIcon icon="FileIcon" />
            Ayrıntılı PDF İndir
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import {
  BButton, BCard, BCol, BFormDatepicker, BFormGroup, BRow,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'FilterDataCard',
  components: {
    BFormGroup, BCol, BButton, BCard, BRow, vSelect, BFormDatepicker,
  },
  props: {
    getReport: {
      type: Function,
      required: true,
    },
  },
  computed: {
    filterData() {
      return this.$store.getters['salesDigitalResponse/getFilterData']
    },
    digitalResponse() {
      return this.$store.getters['salesDigitalResponse/getReport']
    },
    brands() {
      return this.$store.getters['brands/getBrands']
    },
    meets() {
      return this.$store.getters['meets/getMeets']
    },
    users() {
      return this.$store.getters['users/getUsers']
    },
    downloadExcel() {
      const urlParams = []
      if (this.filterData.sdate) {
        urlParams.push(`sdate=${this.filterData.sdate}`)
      }
      if (this.filterData.edate) {
        urlParams.push(`edate=${this.filterData.edate}`)
      }
      if (this.filterData.id_com_brand) {
        this.filterData.id_com_brand.forEach(e => {
          urlParams.push(`id_com_brand[]=${e}`)
        })
      }
      if (this.filterData.id_com_meet) {
        this.filterData.id_com_meet.forEach(e => {
          urlParams.push(`id_com_meet[]=${e}`)
        })
      }
      if (this.filterData.id_com_user) {
        this.filterData.id_com_user.forEach(e => {
          urlParams.push(`id_com_user[]=${e}`)
        })
      }
      if (this.filterData.status) {
        this.filterData.status.forEach(e => {
          urlParams.push(`status[]=${e}`)
        })
      }
      const params = urlParams.join('&')
      let url = `${this.$store.state.app.baseURL}/exports/reports/sales/digital_response/excel?token=${localStorage.getItem('downloadToken')}`
      if (params) {
        url += `&${params}`
      }
      return url
    },
    showPDF() {
      const urlParams = []
      if (this.filterData.sdate) {
        urlParams.push(`sdate=${this.filterData.sdate}`)
      }
      if (this.filterData.edate) {
        urlParams.push(`edate=${this.filterData.edate}`)
      }
      if (this.filterData.id_com_brand) {
        this.filterData.id_com_brand.forEach(e => {
          urlParams.push(`id_com_brand[]=${e}`)
        })
      }
      if (this.filterData.id_com_meet) {
        this.filterData.id_com_meet.forEach(e => {
          urlParams.push(`id_com_meet[]=${e}`)
        })
      }
      if (this.filterData.id_com_user) {
        this.filterData.id_com_user.forEach(e => {
          urlParams.push(`id_com_user[]=${e}`)
        })
      }
      if (this.filterData.status) {
        this.filterData.status.forEach(e => {
          urlParams.push(`status[]=${e}`)
        })
      }
      const params = urlParams.join('&')
      let url = `${this.$store.state.app.baseURL}/exports/reports/sales/digital_response/pdf?token=${localStorage.getItem('downloadToken')}`
      if (params) {
        url += `&${params}`
      }
      return url
    },
    showDetailPDF() {
      const urlParams = []
      if (this.filterData.sdate) {
        urlParams.push(`sdate=${this.filterData.sdate}`)
      }
      if (this.filterData.edate) {
        urlParams.push(`edate=${this.filterData.edate}`)
      }
      if (this.filterData.id_com_brand) {
        this.filterData.id_com_brand.forEach(e => {
          urlParams.push(`id_com_brand[]=${e}`)
        })
      }
      if (this.filterData.id_com_meet) {
        this.filterData.id_com_meet.forEach(e => {
          urlParams.push(`id_com_meet[]=${e}`)
        })
      }
      if (this.filterData.id_com_user) {
        this.filterData.id_com_user.forEach(e => {
          urlParams.push(`id_com_user[]=${e}`)
        })
      }
      if (this.filterData.status) {
        this.filterData.status.forEach(e => {
          urlParams.push(`status[]=${e}`)
        })
      }
      const params = urlParams.join('&')
      let url = `${this.$store.state.app.baseURL}/exports/reports/sales/digital_response/pdf?token=${localStorage.getItem('downloadToken')}`
      if (params) {
        url += `&${params}&detail=1`
      }
      return url
    },
  },
  created() {
    this.getUsers()
    this.getBrands()
    this.getMeets()
  },
  methods: {
    getBrands() {
      this.$store.dispatch('brands/brandsList', {
        select: [
          'com_brand.id AS id',
          'com_brand.name AS title',
        ],
      })
    },
    getUsers() {
      this.$store.dispatch('users/usersList', {
        select: [
          'com_user.id AS id',
          'com_user.name AS name',
          'com_brand.name AS brand',
          'com_user_type.title AS type',
        ],
        where: {
          'com_user.status': 1,
          'com_user.id_com_department': 2,
          'com_user.id_com_user_type': 5,
        },
      })
    },
    getMeets() {
      this.$store.dispatch('meets/meetsList', {
        select: [
          'com_meet.id AS id',
          'com_meet.title AS title',
        ],
        where: {
          'com_meet.id_com_meet_group': 1,
        },
      })
    },
  },
}
</script>
