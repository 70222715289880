<template>
  <b-table
    striped
    hover
    responsive
    small
    :fields="fields"
    :items="reportData"
  >
    <template #cell(interview_number)="data">
      <b-link
        :to="'/interviews/view/' + data.item.interview_number"
        target="_blank"
      >
        {{ data.item.interview_number }}
      </b-link>
    </template>
    <template #cell(brand)="data">
      {{ data.item.brand }} {{ data.item.model }}
    </template>
    <template #cell(meet)="data">
      {{ data.item.meet }}
      <div
        v-if="data.item.ads"
        class="font-small-2 text-danger"
      >
        (Dijital Reklam)
      </div>
    </template>
    <template #cell(interview_status_text)="data">
      <b-badge
        v-if="data.item.interview_status_text === 'Talep Yanıt Bekliyor'"
        variant="danger"
        class="font-small-3"
      >
        {{ data.item.interview_status_text }}
      </b-badge>
      <template v-else>
        {{ data.item.interview_status_text === '0' ? 'Talep Yanıt Bekliyor': data.item.interview_status_text }}
        <div>
          <small
            v-if="data.item.status === '1'"
            class="text-success font-small-2"
          >Kapalı</small>
          <small
            v-if="data.item.status !== '1'"
            class="text-warning font-small-2"
          >Açık</small>
        </div>
      </template>
    </template>
    <template #cell(request)="data">
      {{ moment(data.item.request).format('DD.MM.YYYY HH:mm') }}
    </template>
    <template #cell(response)="data">
      {{ moment(data.item.response).format('DD.MM.YYYY HH:mm') }}
    </template>
    <template #cell(times)="data">
      <div class="d-flex align-items-center">
        <div class="mr-1">
          <FeatherIcon
            v-b-tooltip.hover.top="data.item.seconds <= 1800? 'Hedef Süre Başarılı!' : 'Hedef Süre Başarısız!'"
            variant="flat-primary"
            :icon="data.item.seconds < 1800? 'ArrowUpCircleIcon' : 'ArrowDownCircleIcon'"
            :class="data.item.seconds < 1800? 'text-success' : 'text-danger'"
            size="24"
          />
        </div>
        <div
          v-if="!data.item.requestStatus"
          class="text-danger"
        >
          <div>
            Bekleme Süresi
          </div>
          <div class="font-weight-bolder">
            <span v-if="data.item.dateDiff.days">
              {{ data.item.dateDiff.days }} Gün
            </span>
            <span v-if="data.item.dateDiff.hours">
              {{ data.item.dateDiff.hours }} sa
            </span>
            <span v-if="data.item.dateDiff.minutes">
              {{ data.item.dateDiff.minutes }} dk
            </span>
          </div>
        </div>
        <div v-else>
          <div class="font-weight-bolder">
            <span v-if="data.item.dateDiff.days">
              {{ data.item.dateDiff.days }} Gün
            </span>
            <span v-if="data.item.dateDiff.hours">
              {{ data.item.dateDiff.hours }} sa
            </span>
            <span v-if="data.item.dateDiff.minutes">
              {{ data.item.dateDiff.minutes }} dk
            </span>
          </div>
          <div class="text-muted">
            <span v-if="data.item.allTimesDateDiff.days">
              {{ data.item.allTimesDateDiff.days }} Gün
            </span>
            <span v-if="data.item.allTimesDateDiff.hours">
              {{ data.item.allTimesDateDiff.hours }} sa
            </span>
            <span v-if="data.item.allTimesDateDiff.minutes">
              {{ data.item.allTimesDateDiff.minutes }} dk
            </span>
            <span class="text-danger">*</span>
          </div>
        </div>
      </div>
    </template>
  </b-table>
</template>

<script>
import {
  BTable, BLink, BBadge, VBTooltip,
} from 'bootstrap-vue'

export default {
  name: 'DetailTable',
  components: {
    BTable,
    BLink,
    BBadge,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    reportData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'interview_number',
          label: 'Görüşme No',
          thClass: 'font-small-2 width-100 text-nowrap',
          tdClass: 'font-small-2 width-100 text-nowrap',
        },
        {
          key: 'brand',
          label: 'Araç',
          thClass: 'font-small-2 width-100 text-nowrap',
          tdClass: 'font-small-2 width-100 text-nowrap',
        },
        {
          key: 'meet',
          label: 'Temas Tipi',
          thClass: 'font-small-2 width-100 text-nowrap',
          tdClass: 'font-small-2 width-100 text-nowrap',
        },
        {
          key: 'interview_status_text',
          label: 'Görüşme Durumu',
          thClass: 'font-small-2',
          tdClass: 'font-small-2',
        },
        {
          key: 'request',
          label: 'Talep',
          thClass: 'font-small-2 text-center',
          tdClass: 'font-small-2 text-center',
        },
        {
          key: 'response',
          label: 'İlk Yanıt',
          thClass: 'font-small-2 text-center',
          tdClass: 'font-small-2 text-center',
        },
        {
          key: 'times',
          label: 'Yanıt Süresi',
          thClass: 'font-small-2 width-200',
          tdClass: 'font-small-2 width-200',
        },
      ],
    }
  },
}
</script>

<style scoped>

</style>
