<template>
  <div>
    <filter-data-card :get-report="getReport" />
    <loading v-if="loadingReport === true" />
    <b-card
      v-if="digitalResponse.length > 0"
    >
      <b-row>
        <b-col md="auto">
          <item-statistics
            title="Yanıt Bekliyor"
            icon="ClockIcon"
            color="light-danger"
            :value="counters.pending"
          />
        </b-col>
        <b-col md="auto">
          <item-statistics
            title="Görüşme Devam Ediyor"
            icon="ClockIcon"
            color="light-warning"
            :value="counters.opens"
          />
        </b-col>
        <b-col md="auto">
          <item-statistics
            title="Toplam Talep"
            icon="ClipboardIcon"
            color="light-success"
            :value="counters.totals"
          />
        </b-col>
        <b-col md="auto">
          <item-statistics
            title="Ortalama Yanıt Süresi"
            icon="PhoneForwardedIcon"
            color="light-info"
            :value="counters.responseAVG"
            :value2="counters.allTimesResponseAVG"
          />
        </b-col>
      </b-row>
    </b-card>
    <b-row
      v-if="digitalResponse.length > 0"
      class="match-height"
    >
      <b-col>
        <b-card
          title="Temas Yanıt Oranları (Dakika)"
        >
          <meet-chart />
        </b-card>
      </b-col>
      <b-col>
        <b-card
          title="Marka Yanıt Oranları (Dakika)"
        >
          <b-row>
            <b-col>
              <brand-chart
                :data-labels="Charts.brands.labels"
                :data-series="Charts.brands.series"
              />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-card
      v-if="digitalResponse.length > 0"
      no-body
    >
      <b-table-simple bordered>
        <b-thead>
          <b-tr>
            <b-th>
              Danışman
            </b-th>
            <b-th class="text-center width-100">
              Adet
            </b-th>
            <b-th>
              İlk Yanıt Ortalaması
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <template
            v-for="(item,index) in digitalResponse"
          >
            <b-tr
              :key="index"
            >
              <b-td>
                <div class="d-flex align-items-center">
                  <b-button
                    v-b-tooltip.hover.top="'Ayrıntıları Göster'"
                    variant="flat-primary"
                    size="sm"
                    class="btn-icon"
                    pill
                    @click="showDetails(index)"
                  >
                    <FeatherIcon :icon="item.detailStatus === true ? 'EyeOffIcon' : 'EyeIcon'" />
                  </b-button>
                  <div class="ml-1">
                    <div>
                      {{ item.username }}
                    </div>
                    <small class="text-warning">{{ item.brand }}</small>
                  </div>
                </div>
              </b-td>
              <b-td class="font-small-2 width-250 align-middle text-center">
                <div>
                  <div
                    v-if="item.totals > 0"
                    style="margin-bottom: 4px;"
                  >
                    <b-badge
                      variant="light-success"
                      class="w-100"
                      pill
                    >
                      {{ item.totals }} Yanıtlandı
                    </b-badge>
                  </div>
                  <div v-if="item.pending > 0">
                    <b-badge
                      variant="light-danger"
                      class="w-100"
                      pill
                    >
                      {{ item.pending }} Yanıt bekleyen
                    </b-badge>
                  </div>
                </div>
              </b-td>
              <b-td
                class="width-250 align-middle"
              >
                <div
                  v-if="item.seconds > 0"
                  class="d-flex align-items-center"
                >
                  <div class="mr-1">
                    <FeatherIcon
                      v-b-tooltip.hover.top="item.seconds <= 1800? 'Hedef Süre Başarılı!' : 'Hedef Süre Başarısız!'"
                      variant="flat-primary"
                      :icon="item.seconds < 1800? 'ArrowUpCircleIcon' : 'ArrowDownCircleIcon'"
                      :class="item.seconds < 1800? 'text-success' : 'text-danger'"
                      size="24"
                    />
                  </div>
                  <div>
                    <div class="font-weight-bold">
                      <span v-if="item.questionAvarageTimes.days">
                        {{ item.questionAvarageTimes.days }} Gün
                      </span>
                      <span v-if="item.questionAvarageTimes.hours">
                        {{ item.questionAvarageTimes.hours }} sa
                      </span>
                      <span v-if="item.questionAvarageTimes.minutes">
                        {{ item.questionAvarageTimes.minutes }} dk
                      </span>
                    </div>
                    <div class="text-muted font-small-2">
                      <span v-if="item.allTimesQuestionAvarageTimes.days">
                        {{ item.allTimesQuestionAvarageTimes.days }} Gün
                      </span>
                      <span v-if="item.allTimesQuestionAvarageTimes.hours">
                        {{ item.allTimesQuestionAvarageTimes.hours }} sa
                      </span>
                      <span v-if="item.allTimesQuestionAvarageTimes.minutes">
                        {{ item.allTimesQuestionAvarageTimes.minutes }} dk
                      </span>
                      <span class="text-danger">*</span>
                    </div>
                  </div>
                </div>
              </b-td>
            </b-tr>
            <b-tr
              v-if="item.detailStatus === true"
              :key="'detail_' + index"
            >
              <b-td
                colspan="4"
                class="m-0 p-0"
              >
                <detail-table :report-data="item.details" />
              </b-td>
            </b-tr>
          </template>
        </b-tbody>
      </b-table-simple>

      <b-card-footer>
        <b-row>
          <b-col
            cols="auto"
          >
            Toplam <b>{{ digitalResponse.length }}</b> adet sonuç bulundu.
          </b-col>
          <b-col class="text-right font-small-2">
            <span class="text-danger">*</span> Talep geliş ve ilk yanıt arasındaki gerçek süreyi gösterir.
          </b-col>
        </b-row>
      </b-card-footer>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BCardFooter, VBTooltip,
  BTableSimple, BTr, BTh, BTbody, BTd, BThead, BBadge,
} from 'bootstrap-vue'
import DetailTable from '@/views/Reports/Sales/DigitalResponse/Components/detailTable.vue'
import Loading from '@/views/Reports/Crm/RequestDurations/LoadingReport.vue'
import ItemStatistics from '@/views/Crm/components/ItemStatistics.vue'
import MeetChart from '@/views/Reports/Sales/DigitalResponse/Components/ApexDonutCard.vue'
import BrandChart from '@/views/Reports/Sales/DigitalResponse/Components/ApexBarChart.vue'
import FilterDataCard from '@/views/Reports/Sales/DigitalResponse/Components/filterDataCard.vue'

export default {
  name: 'Index',
  components: {
    FilterDataCard,
    BrandChart,
    MeetChart,
    ItemStatistics,
    Loading,
    DetailTable,
    BCard,
    BRow,
    BCol,
    BButton,
    BCardFooter,
    BTableSimple,
    BTr,
    BTh,
    BTbody,
    BTd,
    BThead,
    BBadge,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      id_com_brand: [],
      id_com_meet: [],
      id_com_user: [],
      status: [0, 1],
      statuses: [
        {
          id: 1,
          title: 'Kapalı Olanlar',
        },
        {
          id: 0,
          title: 'Açık Olanlar',
        },
      ],
      sdate: null,
      edate: null,
      limit: null,
      start: null,
      currentPage: 1,
      perPage: 50,
    }
  },
  computed: {
    digitalResponse() {
      return this.$store.getters['salesDigitalResponse/getReport']
    },
    Charts() {
      return this.$store.getters['salesDigitalResponse/getChartData']
    },
    counters() {
      return this.$store.getters['salesDigitalResponse/getReportCounters']
    },
    filterData() {
      return this.$store.getters['salesDigitalResponse/getFilterData']
    },
    loadingReport() {
      return this.$store.getters['salesDigitalResponse/getLoading']
    },
  },
  methods: {
    getReport() {
      this.$store.dispatch('salesDigitalResponse/report', {
        id_com_brand: this.filterData.id_com_brand,
        id_com_meet: this.filterData.id_com_meet,
        id_com_user: this.filterData.id_com_user,
        status: this.filterData.status,
        sdate: this.filterData.sdate,
        edate: this.filterData.edate,
        limit: this.limit,
        start: this.start,
      })
    },
    showDetails(index) {
      if (this.digitalResponse[index].detailStatus === false || this.digitalResponse[index].detailStatus === '0') {
        this.digitalResponse[index].detailStatus = true
      } else {
        this.digitalResponse[index].detailStatus = false
      }
    },
  },
}
</script>
